export function getLSItem(name: string): object {
  const r = window.localStorage.getItem(name);
  return !!r ? JSON.parse(r) : {};
}

export function setLSItem(name: string, obj: Object): boolean {
  const j = JSON.stringify(obj);
  try {
    window.localStorage.setItem(name, j);
    return true;
  } catch (e) {
    console.error(e)
    return false
  }
}

type LSAction = string;
 
export const LSActions: {[key: string]: LSAction} = {
  DELETE: "delete-item",
  UPSERT: "upsert-item",
  GET: "get-item",
};

export interface Action {
  type: LSAction;
  key: string;
  data?: Object | any[];
  context: string;
}


export default function syncLS(state: any, action: Action) {
  let currentState = getLSItem(action.context);
  switch (action.type) {
    case LSActions.UPSERT: {
      if (action.key in currentState) {
        console.debug(`The given data ${action.key} will be updated by overwriting`)
      } else {
        console.debug(`The given data ${action.key} will be inserted`)
      }
      let newState: {[key: string]: any} = {...currentState}
      newState[action.key] = action.data
      setLSItem(action.context, newState)
      return newState
    }
    case LSActions.DELETE: {
      if (action.key in currentState) {
        let newState: {[key: string]: any} = {...currentState}
        delete newState[action.key]
        setLSItem(action.context, newState)
        return newState
      } else {
        return currentState
      }
    }
  }
}

