import React, { useEffect } from "react";
import logo from "../../../static/enpal_vector.svg";
import styles from "./homescreen.module.css";
import CustomButton from "../../atoms/custom-button/custom-button";
import { useHistory } from "react-router";
import { setLSItem, getLSItem } from "../../../utils/saveData";

const HomeScreen = () => {
  useEffect(() => {
    //initialize Notification Preferences
    const currentSettings = getLSItem("notificationPreferences")
    if (!currentSettings.initialized) {
      const defaults = {
        preferredMedia: "phone",
        notReceiveAdvice: false,
        notReceiveSMS: false,
        notReceiveEmail: false,
        initialized: new Date()
      };
      setLSItem("notificationPreferences", defaults)
    }
  });

  const history = useHistory();
  return (
    <div className={styles.homeScreen}>
      <img src={logo} className={"enpalLogo"} alt="logo" />
      <div className={styles.content}>
        <h1 className={styles.title}>
          Machen Sie sich <emphasis>unabhängig</emphasis> von Ihrem
          Stromanbieter
        </h1>
        <div className={styles.interactionCard}>
          <h2>Enpal-Solar-Planer</h2>
          <p>
            Planen Sie in 5 Minuten Ihre eigene Solaranlage und finden Sie
            heraus wie viel Sie sparen werden.
          </p>
          <CustomButton
            onClickHandler={(e) => history.push("/bevor-wir-starten")}
            filled={true}
            text={"Jetzt Ersparnis errechnen"}
          />
        </div>
      </div>
    </div>
  );
};
export default HomeScreen;
