import * as React from "react";
import { ReactComponent as CheckedCheckmark } from "../../../static/Checkmark_checked.svg";
import { ReactComponent as UncheckedCheckmark } from "../../../static/Checkmark_unchecked.svg";
import { ReactComponent as Arrow } from "../../../static/arrow-right.svg";
import styles from './checkmark.module.css';

interface CheckmarkProps extends React.FunctionComponent {
  title: string;
  description: string;
  isChecked: boolean;
  link?: string;
  linkText?: string;
  clickable?: boolean;
  onClick?: Function;
  primaryColorOverride?: string;
  secondaryColorOverride?: string;
}

const Checkmark: React.FunctionComponent<CheckmarkProps> = ({
  title,
  description,
  link,
  linkText,
  clickable,
  onClick,
  isChecked,
  primaryColorOverride,
  secondaryColorOverride
}) => {
  isChecked = isChecked === undefined ? true : isChecked;
  let colorOverrides: { [key: string]: string } = {}
  if (primaryColorOverride) {
    colorOverrides["--dark-color"] = primaryColorOverride;
  }
  if (secondaryColorOverride) {
    colorOverrides['--lighter-color'] = secondaryColorOverride;
  }
  return (
    <div
      onClick={(e) => {
        if (clickable && !!onClick) {
          onClick(e);
        }
      }}
      className={styles.container}
      style={{...colorOverrides}}
    >
      {isChecked ? <CheckedCheckmark /> : <UncheckedCheckmark />}
      <div className={styles.description}>
        <h5>{title}</h5>
        <p>{description}</p>
        {!!link && (
          <a href={link}>
            {linkText} <Arrow className={styles.arrow}/>
          </a>
        )}
      </div>
    </div>
  );
};

export default Checkmark;
