import * as React from "react";
import styles from "./custom-button.module.css";
import cn from "classnames";

interface ButtonProps extends React.FunctionComponent {
  text: string;
  filled: boolean;
  disabled: boolean;
  onClickHandler: React.MouseEventHandler<HTMLDivElement>;
  onDisabledClick: Function;
}

const DefaultButton: React.FunctionComponent<ButtonProps> = ({
  text,
  filled,
  disabled,
  onClickHandler,
  onDisabledClick,
}) => {
  onClickHandler = !disabled
    ? onClickHandler
    : (e) => {
        console.error("Button is disabled");
        onDisabledClick()
      };
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (typeof onClickHandler === "function") {
          onClickHandler(e);
        } else {
          console.error("onClickHandler is not a function", onClickHandler);
        }
      }}
      className={cn(
        styles.button,
        !filled && styles.outline,
        disabled && styles.disabled
      )}
    >
      {text}
    </div>
  );
};

export default DefaultButton;
