import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { ReactComponent as MapPin } from "../../../static/Marker.svg";
import { ReactComponent as LocateMe } from "../../../static/LocateMe.svg";
import styles from "./mapsView.module.css";

interface MapProps extends React.FunctionComponent {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  className: string;
  setSelectedLocation: Function;
  setHasInteracted: Function;
  setCenter: Function;
  setZoom: Function;
}

const getMapOptions = (maps: GoogleMapReact.Maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    styles: [
      {
        featureType: "poi.business",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 5,
    maxZoom: 20,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.HYBRID,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },
    rotateControl: false,
    zoomControl: false,
    clickableIcons: false,
  };
};

function MapsView({
  center,
  zoom,
  className,
  setSelectedLocation,
  setHasInteracted,
  setCenter,
  setZoom
}: MapProps) {
  const [markerPos, setMarkerPos] = useState({ lat: 0, lng: 0 });
  return (
    <div className={className} id="map-container">
      <LocateMe
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
              setMarkerPos({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
              setSelectedLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
              setHasInteracted(true)
              setZoom(20);
              ;
            },
            (positionError) => {
              alert("Problem Locating you: " + JSON.stringify(positionError));
            }
          );
          console.log("locate Me");
        }}
        className={styles.locateMe}
      />
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBG2IdJFOXeJHBDAywxSVy4gja35-aFswo" }}
        defaultCenter={{ lat: 52.5103, lng: 13.46417 }}
        defaultZoom={10}
        center={center}
        zoom={zoom}
        options={getMapOptions}
        onClick={({ x, y, lat, lng, event }) => {
          setMarkerPos({ lat, lng });
          setSelectedLocation({ lat, lng });
          setHasInteracted(true);
          console.log(x, y, lat, lng, event);
        }}
        onDrag={(map) => {
          setHasInteracted(true);
        }}
        
      >
        {markerPos.lat !== 0 ? (
          <div
            //@ts-ignore
            lat={markerPos.lat}
            lng={markerPos.lng}
            className={styles.markerDiv}
          >
            <MapPin />
          </div>
        ) : null}
      </GoogleMapReact>
    </div>
  );
}

export default MapsView;
