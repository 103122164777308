import React, { useState, useEffect, useReducer } from "react";
import BaseLayout from "../../atoms/baseLayout/baseLayout";
import CustomInput from "../../atoms/defaultInput/defaultInput";
import styles from "./addressFinder.module.css";
import CustomButton from "../../atoms/custom-button/custom-button";
import MapView from "../../atoms/mapsView/mapsView";

import syncLSData, { getLSItem, LSActions } from "../../../utils/saveData";
import useGeoPosition from "../../atoms/mapsView/utils/geocoding";
// import { useHistory } from "react-router";

const AddressFinder = () => {
  const CONTEXT = "leadData";
  const [savedData, dispatch] = useReducer(syncLSData, CONTEXT, (s) =>
    getLSItem(s)
  );
  const [submitAddress, setSubmitAddress] = useState("");
  const [hasInteracted, setHasInteracted] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(10);
  // const history = useHistory();
  // eslint-disable-next-line
  const [position, geoloading, geoerror, setPosition] = useGeoPosition(
    "AIzaSyBG2IdJFOXeJHBDAywxSVy4gja35-aFswo",
    submitAddress
  );

  useEffect(() => {
    if ((position.lat !== 52.5103, position.lng !== 13.46417)) {
      setHasInteracted(true);
    }
    dispatch({
      context: CONTEXT,
      key: "position",
      data: { ...position },
      type: LSActions.UPSERT,
    });
  }, [position]);

  const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 });
  const handleSetSelectedLocation = (location) => {
    setSelectedLocation(location);
    dispatch({
      context: CONTEXT,
      key: "selectedLocation",
      data: { ...location },
      type: LSActions.UPSERT,
    });
  };
  return (
    <BaseLayout
      title={
        !hasInteracted
          ? "Wo sollen wir Sonne in Strom verwandeln?"
          : "Bitte markieren Sie das Dach des Hauses"
      }
      showCommunicationPreferences={true}
    >
      <div className={styles.mapCard}>
        <CustomInput
          label={"Ihre Adresse"}
          placeholder={"Beispielstraße 10, Beispielstadt"}
          label_suffix={"Zur Bestimmung der Ausrichtung"}
          type={"address"}
          // onChangeHandler={(e) => setAddressInput(e.target.value)}
          onChangeHandler={(e) =>
            dispatch({
              context: CONTEXT,
              key: "address",
              data: e.target.value,
              type: LSActions.UPSERT,
            })
          }
          isValid={savedData.address?.length > 10}
          value={savedData.address ?? ""}
          onHitEnter={() => {
            setSubmitAddress(savedData.address);
            // dispatch({
            //   context: CONTEXT,
            //   key: "address",
            //   data: savedData.address,
            //   type: LSActions.UPSERT,
            // });
            setZoomLevel(20);
            console.log("Submit address");
          }}
        />
        <MapView
          className={styles.mapContainer}
          zoom={zoomLevel}
          center={position}
          setSelectedLocation={handleSetSelectedLocation}
          setHasInteracted={setHasInteracted}
          setCenter={setPosition}
          setZoom={setZoomLevel}
        />
        {/* <img src={ZoomedOut} className={styles.mapContainer}></img> */}
        <div className={styles.floatingButton}>
          <CustomButton
            filled={true}
            text={"Zur Dachauswahl"}
            onClickHandler={(e) => {
              console.log(e);
              console.log(selectedLocation);
            }}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddressFinder;
