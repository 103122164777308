 import { useState, useEffect, useCallback} from 'react'
 import axios from 'axios';

 const useGeoPosition = (key: string, address: string) => {
   const [position, setPosition] = useState({ lat: 52.5103, lng: 13.46417 });
   const [error, setError] = useState(false);
   const [loading, setLoading] = useState(false);

   const fetchLatandLng = useCallback(async (address, key) => {
     try {
           setLoading(true);
           console.log("Requesting geocoded address")
           const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`);
           console.log(res)
           const result = res.data.results[0].geometry.location;
           if (result.lat !== null && result.lng !== null) {
             console.log("Got Result", result)
             setPosition({lat: result.lat, lng: result.lng})
           }
           else {
             setError(true);
           }
           setLoading(false);
       }
     catch (error) {
        setLoading(false);
        setError(true);
     }
   }, [])


   useEffect(() => {
     if (address){
       fetchLatandLng(address, key);
     } else {
       console.log("Not geocoding address due to undefined address", address)
     }
   }, [address, fetchLatandLng, key])

   return [position, loading, error, setPosition]
 }

 export default useGeoPosition;