import React, { useReducer } from "react";
import BaseLayout from "../../atoms/baseLayout/baseLayout";
import CustomInput from "../../atoms/defaultInput/defaultInput";
import CustomButton from "../../atoms/custom-button/custom-button";
import styles from "./contactForm.module.css";
import { ReactComponent as LockIcon } from "../../../static/lock.svg";
import { ReactComponent as Arrow } from "../../../static/arrow-right.svg";
import CommunicationPrefsButton from "../../atoms/notficationPreferences/notificationPreferences";
import CheckMark from "../../atoms/checkmark/checkmark";

import syncLSData, { getLSItem, LSActions } from "../../../utils/saveData";
import { useHistory } from "react-router";

const ContactForm = () => {
  const history = useHistory();
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("+49 ");
  const CONTEXT = "leadData";
  const [savedData, dispatch] = useReducer(syncLSData, CONTEXT, (s) =>
    getLSItem(s)
  );

  const allInputValid =
    savedData.email?.length > 5 &&
    savedData.email?.includes("@") &&
    savedData.name?.length > 5 &&
    savedData.name?.includes(" ") &&
    savedData.phone?.length > 8;

  return (
    <BaseLayout
      title={"Mit wem haben wir die Ehre?"}
      description={
        <p>
          Uns ist es sehr wichtig für jeden unserer Kunden eine persönliche
          Lösung zu finden.
          <span>
            Daher benötigen wir Ihre Kontaktdaten um Sie gut beraten zu können -
            und keine Sorge, sie haben volle Kontrolle
          </span>
        </p>
      }
    >
      <div className={styles.fieldsContainer}>
        <CustomInput
          label={"Ihr Name"}
          placeholder={"Vorname Nachname"}
          label_suffix={"Zum höflichen Umgang"}
          type={"text"}
          //unit_suffix={"€"}
          // onChangeHandler={(e) => setName(e.target.value)}
          onChangeHandler={(e) =>
            dispatch({
              context: CONTEXT,
              key: "name",
              data: e.target.value,
              type: LSActions.UPSERT,
            })
          }
          isInvalid={
            savedData.name?.length > 5 && !savedData.name?.includes(" ")
          }
          value={savedData.name ?? ""}
        />
        <CustomInput
          label={"ihre email"}
          placeholder={"E-Mail Adresse"}
          label_suffix={"Damit Sie zu Ihrer Konfiguration zurück können"}
          type={"email"}
          //unit_suffix={"€"}
          // onChangeHandler={(e) => setEmail(e.target.value)}
          onChangeHandler={(e) =>
            dispatch({
              context: CONTEXT,
              key: "email",
              data: e.target.value,
              type: LSActions.UPSERT,
            })
          }
          isInvalid={
            savedData.email?.length > 5 && !savedData.email?.includes("@")
          }
          value={savedData.email ?? ""}
        />
        <CustomInput
          label={"Ihre Handynummer"}
          placeholder={"+49 151 19195582"}
          label_suffix={"Für mögliche Rückfragen"}
          type={"phone"}
          //unit_suffix={"€"}
          onChangeHandler={(e) =>
            dispatch({
              context: CONTEXT,
              key: "phone",
              data: e.target.value,
              type: LSActions.UPSERT,
            })
          }
          //isValid={text.length > 10}
          value={savedData.phone ?? ""}
          onHitEnter={() => {
            if (allInputValid) {
              history.push("/wo-soll-es-losgehen");
            }
          }}
        />
        <div>
          <CustomButton
            text={"Weiter zur Konfiguration"}
            onClickHandler={(e) => history.push("/wo-soll-es-losgehen")}
            onDisabledClick={() => {
              console.log(savedData);
              console.log(savedData.email?.length > 5)
              console.log(savedData.email?.includes("@"))
              console.log(savedData.name?.length > 5)
              console.log(savedData.name?.includes(" "))
              console.log(savedData.phone?.length > 8)
            }}
            filled={true}
            disabled={!allInputValid}
          />
          <div className={styles.privacyNotice}>
            <LockIcon className={styles.lockIcon} />
            <p>
              Mit Angabe Ihrer Daten stimmen Sie unserer Datenschutzbestimmung
              zu. Dieser können Sie zu jeder Zeit wiedersprechen.
            </p>
          </div>
          <div className={styles.moreAboutPrivacy}>
            <Arrow className={styles.arrowToMore} />
            Mehr über Datenschutz
            <Arrow className={styles.arrowToMore} />
          </div>
          <div className={styles.communicationPreferencesIntro}>
            <CommunicationPrefsButton />
            <p>
              Nutzen Sie diesen Knopf um Ihre Kommunikations-Einstellungen zu
              verwalten
            </p>
          </div>
          <div className={styles.privacyPromises}>
            <h3>Unsere 5 Datenschutz-Versprechen</h3>
            <CheckMark
              title={"Verschlüsselte Datenübertragung"}
              description={
                "Jegliche Daten die Sie Enpal anvertrauen werden sicher verschlüsselt und erst dann übertragen."
              }
              link={"https://enpal.de/datenschutz"}
              linkText={"Mehr dazu"}
              isChecked={true}
              key={"encrypted"}
              primaryColorOverride={"var(--medium-grey)"}
              secondaryColorOverride={"var(--light-grey)"}
            />
            <CheckMark
              title={"Sie haben volle Kontrolle"}
              description={
                "Als Enpal Nutzer haben Sie volle Kontrolle über Ihre Daten. Wenn Sie wünschen können Sie diese jederzeit löschen lassen."
              }
              link={"https://enpal.de/datenschutz"}
              linkText={"Mehr dazu"}
              isChecked={true}
              key={"control"}
              primaryColorOverride={"var(--medium-grey)"}
              secondaryColorOverride={"var(--light-grey)"}
            />
            <CheckMark
              title={"Nur notwendige Daten werden abgefragt / gespeichert"}
              description={
                "Jegliche Daten die wir von Ihnen erhalten sind notwendig für die Erbringung unserer Leistungen. Wir beschränken Ihre Daten auf das Minimum."
              }
              link={"https://enpal.de/datenschutz"}
              linkText={"Mehr dazu"}
              isChecked={true}
              key={"only-necessary"}
              primaryColorOverride={"var(--medium-grey)"}
              secondaryColorOverride={"var(--light-grey)"}
            />
            <CheckMark
              title={"Geprüfte Schulung von Mitarbeitern für Datenschutz"}
              description={
                "Jeder Enpal Mitarbeiter durchläuft eine geprüfte Schulung um unsere hohen Datenschutzansprüche einzuhalten."
              }
              link={"https://enpal.de/datenschutz"}
              linkText={"Mehr dazu"}
              isChecked={true}
              key={"training"}
              primaryColorOverride={"var(--medium-grey)"}
              secondaryColorOverride={"var(--light-grey)"}
            />
            <CheckMark
              title={"Volle Kontrolle über unsere Kommunikation mit Ihnen"}
              description={
                "Als Enpal Nutzer haben Sie jederzeit die Möglichkeit unsere Kommunikation mit Ihnen anzupassen. "
              }
              link={"https://enpal.de/datenschutz"}
              linkText={"Mehr dazu"}
              isChecked={true}
              key={"communication-control"}
              primaryColorOverride={"var(--medium-grey)"}
              secondaryColorOverride={"var(--light-grey)"}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ContactForm;
