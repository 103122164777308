import * as React from "react";
import cn from "classnames";
import styles from "./defaultInput.module.css";

interface InputProps extends React.FunctionComponent {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  isInvalid: boolean;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unit_suffix?: string;
  label_suffix?: string;
  onHitEnter?: Function;
}

const DefaultInput: React.FunctionComponent<InputProps> = ({
  label,
  placeholder,
  value,
  type,
  isInvalid,
  onChangeHandler,
  unit_suffix,
  label_suffix,
  onHitEnter
}) => {
  return (
    <div>
      <label className={styles.inputLabel}>
        {label}{" "}
        {!!label_suffix && (
          <span className={styles.labelSuffix}>• {label_suffix}</span>
        )}
      </label>
      <input
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChangeHandler}
        className={cn(
          styles.inputBox,
          !!unit_suffix && styles.useUnitSuffix,
          !!isInvalid && styles.invalidInput
        )}
        onKeyUp={(e: React.KeyboardEvent) => {
          if (e.key === "Enter" && !!onHitEnter) {
            onHitEnter()
          }
        }}
      />
      {!!unit_suffix && (
        <span className={styles.unitSuffix}>{unit_suffix}</span>
      )}
    </div>
  );
};

export default DefaultInput;
