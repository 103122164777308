import "./styles/app.css";
import HomeScreen from "./components/slides/homescreen/homescreen";
import PreStart from "./components/slides/preStart/preStart";
import ContactForm from './components/slides/contactForm/contactForm';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AddressFinder from "./components/slides/address-finder/addressFinder";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/bevor-wir-starten">
          <PreStart/>
        </Route>
        <Route path="/wo-soll-es-losgehen">
          <AddressFinder/>
        </Route>
        <Route path="/kontakt-daten">
          <ContactForm/>
        </Route>
        <Route path="/">
          <HomeScreen/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
