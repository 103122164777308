import * as React from "react";
import logo from "../../../static/enpal_vector.svg";
import styles from "./baseLayout.module.css";
import CommunicationPrefsButton from "../../atoms/notficationPreferences/notificationPreferences";

interface BaseLayoutProps extends React.FunctionComponent {
  title: string;
  description: string | React.ReactNode;
  showCommunicationPreferences?: boolean;
}

const BaseLayout: React.FunctionComponent<BaseLayoutProps> = ({
  title,
  description,
  children,
  showCommunicationPreferences
}) => {
  return (
    <div className={styles.container}>
      <img src={logo} className={"enpalLogo"} alt="logo" />
      {showCommunicationPreferences && <CommunicationPrefsButton/>}
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        {!!description && <p className={styles.description}>{description}</p>}
        {children}
      </div>
    </div>
  );
};

export default BaseLayout;
