import * as React from "react";
import { useState, useReducer } from "react";
import cn from "classnames";
import { ReactComponent as Chatbubble } from "../../../static/chat_bubble.svg";
import { ReactComponent as Arrow } from "../../../static/arrow-right.svg";
import { ReactComponent as CloseButton } from "../../../static/close_button.svg";
import styles from "./notificationPreferences.module.css";

import syncLSData, { getLSItem, LSActions } from "../../../utils/saveData";

interface PreferencesProps {
  preferredMedia: string;
  notReceiveAdvice: boolean;
  notReceiveEmails: boolean;
  notReceiveSMS: boolean;
  setShowPreferenceCenter: Function;
  setReceiveEmails: Function;
  setReceiveAdvice: Function;
  setReceiveSMS: Function;
  setPreferredMedia: Function;
}

const PreferencesCenter: React.FunctionComponent<PreferencesProps> = ({
  preferredMedia,
  notReceiveAdvice,
  notReceiveEmails,
  notReceiveSMS,
  setShowPreferenceCenter,
  setReceiveEmails,
  setReceiveAdvice,
  setReceiveSMS,
  setPreferredMedia,
}) => {
  return (
    <div className={styles.preferencesCenter}>
      <CloseButton
        className={styles.closeButton}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          //console.log(e);
          setShowPreferenceCenter(false);
          //console.log(setShowPreferenceCenter);
        }}
      />
      <div className={styles.container}>
        <h3>Kommunikations-Einstellungen</h3>
        <span className={styles.preferredMediaTitle}>
          Präferiertes Kontaktmedium
        </span>
        <div className={styles.selectSlider}>
          <span
            className={cn(preferredMedia === "phone" && styles.selected)}
            onClick={() => setPreferredMedia("phone")}
          >
            Telefon
          </span>
          <span
            className={cn(preferredMedia === "sms" && styles.selected)}
            onClick={() => setPreferredMedia("sms")}
          >
            SMS
          </span>
          <span
            className={cn(preferredMedia === "email" && styles.selected)}
            onClick={() => setPreferredMedia("email")}
          >
            Email
          </span>
        </div>
        <div className={styles.checkBoxContainer}>
          <span>Keine Beratung erhalten</span>
          <label
            className={styles.switch}
            onClick={(e) => {
              //console.log(e);
              setReceiveAdvice(!notReceiveAdvice);
            }}
          >
            <input
              type="checkbox"
              checked={notReceiveAdvice}
              onChange={(e) => console.log(e)}
            />
            <span className={cn(styles.slider, styles.round)}></span>
          </label>
          <span>Keine E-Mails erhalten</span>
          <label
            className={styles.switch}
            onClick={() => setReceiveEmails(!notReceiveEmails)}
          >
            <input
              type="checkbox"
              checked={notReceiveEmails}
              onChange={(e) => console.log(e)}
            />
            <span className={cn(styles.slider, styles.round)}></span>
          </label>
          <span>Keine SMS erhalten</span>
          <label
            className={styles.switch}
            onClick={() => setReceiveSMS(!notReceiveSMS)}
          >
            <input
              type="checkbox"
              checked={notReceiveSMS}
              onChange={(e) => console.log(e)}
            />
            <span className={cn(styles.slider, styles.round)}></span>
          </label>
        </div>
        <a href="https://www.enpal.de/datenschutz">
          Alle Kommunikation abbestellen <Arrow className={styles.arrow} />
        </a>
      </div>
    </div>
  );
};

const NotificationPreferences = () => {
  const CONTEXT = "notificationPreferences";
  const [savedData, dispatch] = useReducer(
    syncLSData,
    {
      preferredMedia: "phone",
      notReceiveAdvice: false,
      notReceiveSMS: false,
      notReceiveEmail: false,
    },
    () => getLSItem(CONTEXT)
  );
  const [showPreferencesCenter, setShowPreferenceCenter] = useState(false);
  // const [notReceiveAdvice, setReceiveAdvice] = useState(false);
  // const [notReceiveEmails, setReceiveEmails] = useState(false);
  // const [notReceiveSMS, setReceiveSMS] = useState(false);
  // const [preferredMedia, setPreferredMedia] = useState("phone");
  return (
    <div
      className={styles.button}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowPreferenceCenter(true);
      }}
    >
      <Chatbubble />
      {showPreferencesCenter && (
        <PreferencesCenter
          //@ts-ignore
          preferredMedia={savedData.preferredMedia}
          //@ts-ignore
          notReceiveAdvice={savedData.notReceiveAdvice}
          //@ts-ignore
          notReceiveEmails={savedData.notReceiveEmails}
          //@ts-ignore
          notReceiveSMS={savedData.notReceiveSMS}
          setShowPreferenceCenter={setShowPreferenceCenter}
          setReceiveAdvice={(val: boolean) => {
            dispatch({
              context: CONTEXT,
              key: "notReceiveAdvice",
              data: val,
              type: LSActions.UPSERT,
            });
          }}
          setReceiveEmails={(val: boolean) => {
            dispatch({
              context: CONTEXT,
              key: "notReceiveEmails",
              data: val,
              type: LSActions.UPSERT,
            });
          }}
          setReceiveSMS={(val: boolean) => {
            dispatch({
              context: CONTEXT,
              key: "notReceiveSMS",
              data: val,
              type: LSActions.UPSERT,
            });
          }}
          setPreferredMedia={(val: string) => {
            dispatch({
              context: CONTEXT,
              key: "preferredMedia",
              data: val,
              type: LSActions.UPSERT,
            });
          }}
        />
      )}
    </div>
  );
};

export default NotificationPreferences;
