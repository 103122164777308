import React, { useState } from "react";
import BaseLayout from "../../atoms/baseLayout/baseLayout";
// import logo from "../../../static/enpal_vector.svg";
// import styles from "./preStart.module.css";
import CustomButton from "../../atoms/custom-button/custom-button";
import CheckMark from "../../atoms/checkmark/checkmark";

import { useHistory } from "react-router";

const PreStart = () => {
  const history = useHistory();
  const [isOwner, setOwner] = useState(true);
  const [isYoung, setYoung] = useState(true);
  const [isTechnicallyPossible, setTechnicallyPossible] = useState(true);

  return (
    <BaseLayout
      title={"Bevor wir starten können..."}
      description={
        "Die Enpal Lösung ist leider noch nicht für Jeden verfügbar. Bitte bestätigen Sie, dass diese Punkte für Sie zutreffen:"
      }
    >
      <CheckMark
        title={"Sie sind Haus-Eigentümer"}
        description={
          "Wir können leider nur Anlagen für Haus-Eigentümer anbieten, welche den erzeugten Strom selber nutzen werden"
        }
        link={"https://enpal.de/faq"}
        linkText={"Mehr dazu"}
        onClick={(e) => setOwner(!isOwner)}
        clickable={true}
        isChecked={isOwner}
        key={"owner"}
      />
      <CheckMark
        title={"Sie sind unter 70 Jahre alt"}
        description={
          "Enpal’s Mietmodell läuft für 20 Jahre. Leider erlauben unsere Partner-Banken daher nur Kunden, welche jünger als 70 sind."
        }
        link={"https://enpal.de/faq"}
        linkText={"Mehr dazu"}
        onClick={(e) => setYoung(!isYoung)}
        clickable={true}
        isChecked={isYoung}
        key={"age"}
      />
      <CheckMark
        title={"Ihr Haus hat normale Ziegel"}
        description={
          "Aktuell wir noch nicht in der Lage auf Schiefer-, Bitumen-, oder Bieberschwanz-gedeckten Dächern zu installieren."
        }
        link={"https://enpal.de/faq"}
        linkText={"Mehr dazu"}
        onClick={(e) => setTechnicallyPossible(!isTechnicallyPossible)}
        clickable={true}
        isChecked={isTechnicallyPossible}
        key={"tiles"}
      />
      <CustomButton
        text={"Ja, stimmt alles"}
        onClickHandler={(e) => history.push("/kontakt-daten")}
        filled={true}
        disabled={!isOwner || !isTechnicallyPossible || !isYoung}
      />
    </BaseLayout>
  );
};
export default PreStart;
